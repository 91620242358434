










































import { BaseComponent, Component } from '@/components/BaseComponent';
import Error from '@/components/Error.vue';
import { SendRpcRequestPayload } from '@/types';

@Component({
  components: {
    Error,
  },
})
export default class ResetPasswordLink extends BaseComponent {
  identifier = '';

  successfullySent = false;

  // eslint-disable-next-line class-methods-use-this
  get loginLink() {
    return { name: this.Pages.Login };
  }

  async sendCode() {
    this.error = null;
    const payload: SendRpcRequestPayload = {
      identifier: this.identifier,
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_authorized: this.authorization.check(),
    };
    const clientId = this.authorization.getClientId();
    const lang = this.store.getClientLang();
    if (clientId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      payload.client_id = clientId;
    }
    if (lang) {
      payload.lang = lang;
    }
    const result = await this.pds.sendResetPasswordCode(payload);
    if (result.isFailure()) {
      this.error = result.error;
    } else {
      this.successfullySent = true;
    }
  }
}
